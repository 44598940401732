import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import HC_exporting from 'highcharts/modules/exporting';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { ButtonComponent } from '../../shared/button/button.component';

// Make Highcharts modules available as needed for exporting
HC_exporting(Highcharts);

@Component({
    selector: 'advocate-ui-chart',
    standalone: true,
    imports: [ButtonComponent, HighchartsChartModule],
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements AfterViewInit, OnDestroy {
    @Input() set timestamps(t: Number[] | null | undefined) {
        this.getData(this.data!, t!) // Trigger data update when timestamps input is set
    }
    @Input() set data(d: Highcharts.SeriesOptionsType[] | null | undefined) {
        this.getData(d!, this.timestamps!); // Trigger data update when data input is set
        this.cdr.detectChanges(); // Trigger change detection manually
    }
    Highcharts: typeof Highcharts = Highcharts; // Reference to the Highcharts object
    chartOptions!: Highcharts.Options; // Highcharts configuration options
    chart!: Highcharts.Chart; // Highcharts chart instance
    updateNow = false;
    containerWidth: number = 0;
    private routerSubscription: Subscription = new Subscription();

    // Array of timestamps that controls the x-axis of the chart
    updateTimeStamp: Number[] = this.timestamps!;

    constructor(private cdr: ChangeDetectorRef, private router: Router) { }

    ngAfterViewInit(): void {
        this.calculateContainerWidth();
        this.routerSubscription = this.router.events.subscribe(() => {
            this.calculateContainerWidth();
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    private calculateContainerWidth(): void {
        const container = document.querySelector('.chart-container') as HTMLElement;
        this.containerWidth = container.offsetWidth ?? 1150;
    }

    getData(d: Highcharts.SeriesOptionsType[] | undefined, t: Number[]) {
        if (t !== null && t !== undefined && t.length > 0) {
            this.updateTimeStamp = t; // Update the timestamps if valid
        }
        // Setting up the chart's visual and functional aspects
        this.chartOptions = {
            title: {
                text: '',
                align: 'left'
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    style: {
                        fontSize: '14px'
                    }
                }
            },
            xAxis: {
                type: 'datetime', // Configure the x-axis as datetime type
                categories: this.updateTimeStamp?.map(data => moment(+data * 1000).format('DD-MM-YYYY')),
                labels: {
                    style: {
                        fontSize: '14px'
                    }
                }
            },
            plotOptions: {
                series: {
                    turboThreshold: 1000, // Performance optimization for large data sets
                    label: {
                        connectorAllowed: false
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: '14px'
                }
            },
            series: d, // Data series for the chart

            responsive: { // Responsive rules for different screen sizes
                rules: [{
                    condition: {
                        maxWidth: this.containerWidth
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                        }
                    }
                }]
            },
            credits: { enabled: false },
            chart: {
                width: this.containerWidth,
                height: 400,
                animation: true
            },
        };
    }
}