import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BudgetErrorInterface, CreativeStepErrorInterface, ProximityErrorResponse, SearchAdsErrorInterface, SearchBudgetErrorInterface, SearchKeywordErrorInterface, Step1ErrorInterface, TargetingErrorInterface, YoutubeCreativeErrorInterface } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlingViewModelService {
    private proximityErrorSubject = new BehaviorSubject<ProximityErrorResponse | null>(null);
    private youtubeCreativeError = new BehaviorSubject<YoutubeCreativeErrorInterface | null>(null);

    public campaignStepErrorSignal: WritableSignal<Step1ErrorInterface | null> = signal(null);
    public objectiveStepErrorSignal: WritableSignal<string | null> = signal(null);
    public budgetCreativeErrorSignal: WritableSignal<BudgetErrorInterface | null> = signal(null);
    public targetErrorSignal: WritableSignal<TargetingErrorInterface | null> = signal(null);
    public publishErrorSignal: WritableSignal<string[] | null> = signal(null);
    public creativeStepErrorSignal: WritableSignal<CreativeStepErrorInterface | null> = signal(null);

    // Search
    public searchAdErrorSignal: WritableSignal<SearchAdsErrorInterface | null> = signal(null);
    public searchBudgetErrorSignal: WritableSignal<SearchBudgetErrorInterface | null> = signal(null);
    public searchKeywordErrorSignal: WritableSignal<SearchKeywordErrorInterface | null> = signal(null);

    setProximityError(data: ProximityErrorResponse | null): void {
        this.proximityErrorSubject.next(data);
    }

    getProximityError(): Observable<ProximityErrorResponse | null> {
        return this.proximityErrorSubject.asObservable();
    }

    setYoutubeCreativeError(data: YoutubeCreativeErrorInterface | null): void {
        this.youtubeCreativeError.next(data);
    }

    getYoutubeCreativeError(): Observable<YoutubeCreativeErrorInterface | null> {
        return this.youtubeCreativeError.asObservable();
    }
}