import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

/** @title Datepicker action buttons */
@Component({
  selector: 'advocate-ui-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule
  ]
})
export class DatepickerComponent implements OnChanges {
  @Input() form: FormGroup = new FormGroup({});
  @Input() control: FormControl = new FormControl();
  @Input() selectedDate!: Date;

  @Input() label: string = 'Choose Date';
  @Input() hint: string = 'MM/DD/YYYY';
  @Input() isHint: boolean = false;
  @Input() isDisplayWhite: boolean = false;
  @Input() minDate!: Date;
  @Input() error!: boolean;

  ngOnChanges(): void {
    if (this.selectedDate) {
      this.control.setValue(this.selectedDate);
    }
  }
}