<div class="container">
    <div class="breadcrumbs">
        <span class="breadcrumbs--in-active" (click)="navigateToHome()">{{ "GENERAL.HOME" | translate }}</span>
        <span class="breadcrumbs--active">/</span>
        <span class="breadcrumbs--active">{{ "REPORTS.BREADCRUMBS" | translate }}</span>
    </div>
    <div class="container_header">
        <span>{{ "CAMPAIGN.GROUP_PERFORMANCE" | translate }}</span>
    </div>
    <div *ngIf="{ data: (chartData$ | async)} as vm" class="container-body">
        <ng-container *ngIf="!editCampaign">
            <div class="container-body_filters">
                <div class="container-body_filters--search">
                    <advocate-ui-datepicker [isDisplayWhite]="true" #startDatePicker />
                    <advocate-ui-datepicker [isDisplayWhite]="true" #endDatePicker />
                    <advocate-ui-button [isCurved]="true" state="secondary" size="medium"
                        [name]="'GENERAL.SEARCH' | translate"
                        (click)="searchDatePicker(startDatePicker, endDatePicker)"></advocate-ui-button>
                </div>
            </div>

            <ng-container *ngIf="vm.data?.metrics; else noData">
                <div class="container-body_graph">
                    <div class="container-body_graph--filter">
                        <advocate-ui-chips [chips]="chips" [control]="myFormControl"
                            (selectionChange)="changeChipSelection($event)"></advocate-ui-chips>
                    </div>
                    <advocate-ui-chart [data]="vm.data?.chart" [timestamps]="timestamps$ | async" />
                </div>
                <advocate-ui-matrics [data]="vm.data?.metrics" />
            </ng-container>

            <ng-template #noData>
                <div class="no-data">
                    <h3>{{ "CAMPAIGN.NO_DATA" | translate }}</h3>
                </div>
            </ng-template>

        </ng-container>
        <div class="container-body_info">
            <div class="container_header">
                <span>{{ "CAMPAIGN.CAMPAIGN_INFO" | translate }}</span>
                <advocate-ui-button data-cy="edit-campaign-button" *ngIf="editCampaign" class="edit_campaign_btn"
                    [isCurved]="true" state="secondary" size="medium" (click)="onEditCampaign()"
                    [name]="'CAMPAIGN.EDIT_CAMPAIGN' | translate"></advocate-ui-button>
                <advocate-ui-button *ngIf="!editCampaign" class="edit_campaign_btn" [isCurved]="true" state="secondary"
                    size="medium" [name]="'CAMPAIGN.REQUEST_DETAILED_REPORT' | translate"
                    (click)="openReportDialog()"></advocate-ui-button>
            </div>
            <ng-container *ngIf="data$ | async as campaignData">
                <div class="key-value-container">
                    <div class="key-value-pair">
                        <span class="key">{{ "GENERAL.ADVERTISER" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.advertiser_name" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "GENERAL.NAME" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.name" [selectable]="false" />
                    </div>
                    <hr>
                </div>

                <div class="container_header">
                    <span>{{ "GENERAL.OBJECTIVE" | translate }}</span>
                </div>
                <div class="key-value-container">
                    <div class="key-value-pair">
                        <span class="key">{{ "GENERAL.OBJECTIVE" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.objective" [selectable]="false" />
                    </div>
                    <hr>
                </div>

                <div class="container_header">
                    <span>{{ "CAMPAIGN.BUDGET_DATES" | translate }}</span>
                </div>
                <div class="key-value-container">
                    <div class="key-value-pair">
                        <span class="key">{{ "GENERAL.BUDGET" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.budget" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "GENERAL.DATES" | translate }}</span>
                        <div class="value">
                            <advocate-ui-chips [chips]="campaignData.start_date_time" [selectable]="false" />
                            <span>{{ "GENERAL.TO" | translate }}</span>
                            <advocate-ui-chips [chips]="campaignData.end_date_time" [selectable]="false" />
                        </div>
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "CAMPAIGN.RUNNING_DAYS" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.running_days" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "CAMPAIGN.RUNNING_TIME" | translate }}</span>
                        <div class="value">
                            <advocate-ui-chips [chips]="campaignData.running_time_from" [selectable]="false" />
                            <span>{{ "GENERAL.TO" | translate }}</span>
                            <advocate-ui-chips [chips]="campaignData.running_time_to" [selectable]="false" />
                        </div>
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "CAMPAIGN.PACING" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.pacing" [selectable]="false" />
                    </div>
                    <hr>
                </div>

                <div class="container_header">
                    <span>{{ "CAMPAIGN.TARGETING" | translate }}</span>
                </div>
                <div class="key-value-container">
                    <div class="key-value-pair">
                        <span class="key">{{ "CAMPAIGN.AGE_GROUP" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.age_groups" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "TARGETING.GENDER" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.genders" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "CAMPAIGN.SAVED_AUDIENCE" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.saved_audience" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "CAMPAIGN.ADVANCE_TARGETING" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.advance_targeting" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "CAMPAIGN.ZIP_CODES" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.zip_codes" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "CAMPAIGN.LOCATIONS" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.locations" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "SUMMARY.WHITELISTED_KEYWORDS" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.whitelisted_keywords" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "SUMMARY.BLACKLISTED_KEYWORDS" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.blacklisted_keywords" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "SUMMARY.WHITELISTED_DOMAINS" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.whitelisted_domains" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "SUMMARY.BLACKLISTED_DOMAINS" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.blacklisted_domains" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "TARGETING.CONTRACTS" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.contracts" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "GEOFENCE.GEOFENCE_LOCATIONS" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.predefined_geofences" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "GEOFENCE.GEOFENCE_CSV" | translate }}</span>
                        <a *ngIf="campaignData.geofences; else noFile" [href]="campaignData.geofences.url"
                            [download]="campaignData.geofences.filename">
                            {{ campaignData.geofences.filename }}
                        </a>
                        <ng-template #noFile>
                            <advocate-ui-chips [chips]="[{ id: 'N/A', label: 'N/A', selected: false }]"
                                [selectable]="false" /></ng-template>
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">{{ "TARGETING.GEOGRAPHY.CONGRESSIONAL_DISTRICT" | translate }}</span>
                        <advocate-ui-chips [chips]="campaignData.congressionalDistricts" [selectable]="false" />
                    </div>
                    <hr>
                </div>
            </ng-container>
        </div>
    </div>
</div>